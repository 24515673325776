
import store from "@/store/index";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    props: {
        parent: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: false,
            default: "Preparing model for FROG analysis",
        },
    },
    computed: {
        loading(): boolean {
            let key: string = this.parent + "Loading";
            return store.state[key];
        },
    },
});
