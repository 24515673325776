
import { defineComponent } from "@vue/runtime-core";

import Examples from "@/components/layout/Examples.vue";
import SubmitOptions from "@/components/layout/SubmitOptions.vue";
// import About from "@/components/layout/About.vue";
// import About from "@/components/layout/TaskQueue.vue";
//import TaskQueue from "@/components/layout/TaskQueue.vue";

export default defineComponent({
    components: {
        Examples,
        SubmitOptions,
        //About,
    },
});
