
import { defineComponent } from "vue";
import store, {VUE_APP_APIURL, VUE_APP_BASEURL, VUE_APP_FLOWERURL} from "@/store";

/**
 * Navbar component for providing main links in the application
 */
export default defineComponent({
    data() {
        return {
            items: [
                {
                    label: "Home",
                    icon: "pi pi-fw pi-home",
                    to: "/",
                },
                {
                    label: "REST API",
                    icon: "pi pi-fw pi-sitemap",
                    url: VUE_APP_APIURL + "/docs",
                },
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-desktop",
                    url: VUE_APP_FLOWERURL + "/flower/",
                },
                {
                    label: "Report issue",
                    icon: "pi pi-fw pi-pencil",
                    url: "https://github.com/matthiaskoenig/fbc_curation/issues/new/choose",
                },
                {
                    label: "Source",
                    icon: "pi pi-fw pi-github",
                    url: "https://github.com/matthiaskoenig/fbc_curation",
                },
            ],
        };
    },
});
