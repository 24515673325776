
import store from "@/store/index";
import { defineComponent } from "vue";

import Loading from "@/components/layout/Loading.vue";
/**
 * Component to upload an SBML file to generate report.
 */
export default defineComponent({
    components: {
        Loading,
    },

    data() {
        return {
            modelValue: "",
        };
    },

    methods: {
        updateContent(event): void {
            this.modelValue = event.target.value;
        },

        async submitForm(): Promise<void> {
            store.dispatch("fetchReportUsingSBMLContent", this.modelValue);
        },
    },

    computed: {
        loading(): boolean {
            return store.state.fileLoading;
        },
    },
});
