
import store from "@/store/index";
import { defineComponent } from "vue";

import ComponentMenu from "@/components/layout/ComponentMenu.vue";
import OMEXTree from "@/components/layout/OMEXTree.vue";

/**
 * Component to hold all components to show the generated report.
 */
export default defineComponent({
    components: {
        OMEXTree,
    },

    computed: {
        coreComponents(): Array<Record<string, unknown>> {
            return store.getters.reportBasics;
        },
        rawData(): Array<Record<string, unknown>> {
            return store.getters.rawData;
        },
    },
    methods: {
        /**
         * Updates the searchQuery in Vuex state/localStorage to the currently
         * searched string in the search box.
         */
        updateSearchQuery(e: Event): void {
            store.dispatch("updateSearchQuery", (e.target as HTMLInputElement).value);
        },
    },
});
