import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-col-12 p-lg-6" }
const _hoisted_3 = { class: "p-m-3" }
const _hoisted_4 = { class: "p-col-12 p-lg-6" }
const _hoisted_5 = { class: "p-m-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_submit_options = _resolveComponent("submit-options")!
  const _component_examples = _resolveComponent("examples")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_submit_options)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_examples)
        ])
      ])
    ])
  ]))
}