
import { defineComponent } from "@vue/runtime-core";

import FileUploadForm from "@/components/layout/FileUploadForm.vue";
import URLUpload from "@/components/layout/URLUpload.vue";
import PasteSBML from "@/components/layout/PasteSBML.vue";

export default defineComponent({
    components: {
        FileUploadForm,
        URLUpload,
        PasteSBML,
    },
});
