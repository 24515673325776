
import store from "@/store/index";
import { defineComponent } from "@vue/runtime-core";
import { FilterMatchMode, FilterOperator } from "primevue/api";

/* Components */
import Loading from "@/components/layout/Loading.vue";

/**
 * Component to display list of all example models fetched from API.
 */
export default defineComponent({
    components: {
        Loading,
    },
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                searchUtilField: {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
                },
            },
        };
    },
    created(): void {
        store.dispatch("fetchExamples");
    },

    methods: {
        getExample(exampleId: string): void {
            const payload = {
                exampleId: exampleId,
            };
            store.dispatch("fetchExampleReport", payload);
        },
    },

    computed: {
        /**
         * Reactively returns the list of examples from Vuex state/localStorage.
         */
        examples(): Array<Record<string, unknown>> {
            return store.state.examples;
        },

        /**
         * Reactively returns the loading status of the example(s) from Vuex state/localStorage.
         */
        loading(): boolean {
            return store.state.exampleLoading;
        },
    },
});
