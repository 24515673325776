
import store, {VUE_APP_APIURL, VUE_APP_FRONTENDURL} from "@/store/index";
import { defineComponent } from "vue";

import Loading from "@/components/layout/Loading.vue";
/**
 * Component to upload an SBML file to generate report.
 */
export default defineComponent({
    components: {
        Loading,
    },

    data() {
        return {
            modelValue: "",
        };
    },

    methods: {
        updateURL(event): void {
            this.modelValue = event.target.value;
        },
        async submitForm(): Promise<void> {
            store.dispatch("fetchReportUsingURL", this.modelValue);
        },
    },

    computed: {
        loading(): boolean {
            return store.state.fileLoading;
        },
        api_url(): string {
            return VUE_APP_APIURL;
        },
        example_url(): string {
            return (
                VUE_APP_APIURL +
                "/api/frog/url?url=https://github.com/matthiaskoenig/fbc_curation/raw/version-0.2.0/src/fbc_curation/resources/examples/models/e_coli_core.omex"
            );
        },
    },
});
