
import store, {VUE_APP_APIURL, VUE_APP_FRONTENDURL} from "@/store/index";
import { defineComponent } from "vue";
import axios from "axios";
import Tag from "primevue/tag";


export default defineComponent({
    components: {},
    data() {
        return {
            error: null,
            result: {
                manifest: null,
                frogs: null,
            },
            status: "UNDEFINED",
            status_color: "darkgray",
        };
    },
    computed: {
        task_id(){
            return this.$route.params.id;
        },
        running(){
            if (this.status == "PENDING"){
                return true;
            } else {
                return false;
            }
        },
        success(){
            if (this.status == "SUCCESS"){
                return true;
            } else {
                return false;
            }
        },
        omex_url(): string {
            return VUE_APP_APIURL + "/api/task/omex/" + this.task_id;
        },
        report_url(): string {
            return VUE_APP_FRONTENDURL + "/frog/" + this.task_id;
        },
        tag_class(){
             if (this.status == "PENDING"){
                 return "info";
             } else if (this.status == "SUCCESS"){
                 return "success";
             } else if (this.status == "FAILURE"){
                 return "danger";
             } else if (this.status == "ERROR"){
                 return "danger";
             } else {
                 return "info";
             }
        }
    },
    methods: {
        queryTaskStatus() {
            const url = VUE_APP_APIURL + "/api/task/status/" + this.task_id;
            console.log(url)
            axios.get(url)
                .then((res) => {
                    console.log(res);
                    this.status = res.data.task_status;
                    this.result = res.data.task_result;
                    if (this.status == "SUCCESS"){
                        return null;
                    } else if (this.status == "FAILURE"){
                        return null;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.error = error;
                    this.status = "ERROR";
                    return null;
                });


            setTimeout(() => {
              this.queryTaskStatus();
            }, 2000);
        },
    },
    mounted() {
        this.queryTaskStatus();
  }
});
